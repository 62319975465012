import { useStaticQuery, graphql } from 'gatsby';

export const useCulinaryBoxesPageData = () => {
  const data = useStaticQuery(graphql`
    query {
      culinaryBoxesPageDataJson {
        seo {
          title
          shortTitle
          shortDescription
          description
          url
        }
      }
    }
  `);

  return data.culinaryBoxesPageDataJson;
};
