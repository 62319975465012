import React from 'react';

// External Components
import {
  Section,
  Heading,
  Paragraph,
  Box,
  GridWrapper
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { ImageContainer, TextAndArrow } from 'components';

// Helpers
import { convertApiHtmlText } from 'boxenStopHooks';

export const Selection = ({
  data: { headline, description, image, link_text, link_to }
}) => {
  return (
    // Markup
    <Section id="culinary-boxes__selection">
      <CardWrapper to={link_to}>
        <Image data={image} />
        <TextBlock
          headline={convertApiHtmlText(headline.html)}
          description={description}
          linkText={link_text}
        />
      </CardWrapper>
    </Section>
  );
};

// Elements

const CardWrapper = ({ children, to }) => (
  <NavigationLink
    to={to}
    sx={{ gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'] }}>
    <GridWrapper
      variant="inside.autoColumns"
      // Animation values
      initial="initial"
      whileHover="animate">
      {children}
    </GridWrapper>
  </NavigationLink>
);

const Image = ({ data }) => (
  <ImageContainer
    src={data.image}
    alt={data.alt}
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '1/span 8',
        '2/span 6',
        '1/span 9',
        '2/span 9',
        '3/span 8',
        '4/span 8'
      ]
    }}
  />
);

const TextBlock = ({ headline, description, linkText }) => (
  <Box
    sx={{
      alignSelf: ['start', 'start', 'start', 'end', 'end', 'end'],
      gridColumn: [
        '1/13',
        '2/span 10',
        '11/25',
        '13/span 10',
        '13/span 9',
        '14/span 8'
      ],
      mt: ['5.3rem', '4.8rem', '24.7rem', 0, 0, 0]
    }}>
    <Headline data={headline} />
    <Description data={description} />
    <TextAndArrow
      text={linkText}
      sx={{ mt: ['5.6rem', '6.4rem', '5.4rem', '4.8rem', '5.6rem', '7.2rem'] }}
    />
  </Box>
);

const Headline = ({ data }) => (
  <Heading
    as="h1"
    dangerouslySetInnerHTML={{ __html: data }}
    sx={{
      fontFamily: 'primary.italic',
      fontSize: ['2.4rem', '2.6rem', '2.8rem', '2rem', '2.2rem', '2.6rem'],
      lineHeight: 1.25,
      '& > span': {
        fontFamily: 'primary.mediumStyled'
      }
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    dangerouslySetInnerHTML={{ __html: data }}
    sx={{
      fontFamily: 'body.normal',
      fontSize: ['1.5rem', '1.6rem', '1.6rem', '1.4rem', '1.6rem', '1.6rem'],
      lineHeight: 1.5,
      mt: ['0.8rem', '1.6rem', '1.2rem', '1.2rem', '1.2rem', '1.6rem']
    }}
  />
);
