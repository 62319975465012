import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer, Vector } from 'components';

// Assets
import decoration from 'assets/svg/splash-vector.svg';

// Helpers
import { convertApiHtmlText } from 'boxenStopHooks';

export const Header = ({ data: { headline, description, image } }) => {
  return (
    // Markup
    <Section as='header' id="culinary-boxes__header">
      <ImageWrapper data={image} />
      <Headline data={convertApiHtmlText(headline.html)} />
      <Description data={convertApiHtmlText(description.html)} />
    </Section>
  );
};

// Elements

const ImageWrapper = ({ data }) => (
  <Box
    sx={{
      gridColumn: ['1/13', '1/13', '4/25', '7/25', '5/span 16', '5/span 16'],
      gridRow: 1,
      position: 'relative'
    }}>
    <ImageContainer
      src={data.image}
      alt={data.alt}
      sx={{
        aspectRatio: [
          '1/1.3',
          '1/0.67',
          '1/0.68',
          '1/0.68',
          '1/0.61',
          '1/0.61'
        ],
        width: '100%'
      }}
    />
    <Vector
      src={decoration}
      sx={{
        position: 'absolute',
        right: ['6.2rem', '18rem', '21.4rem', '17.8rem', '24rem', '30rem'],
        bottom: ['8rem', '6.4rem', '7.6rem', '6.2rem', '7.8rem', '10rem'],
        width: ['6.8rem', '5.8rem', '6.2rem', '6rem', '6.4rem', '8rem']
      }}
    />
  </Box>
);

const Headline = ({ data }) => (
  <Heading
    as="h1"
    dangerouslySetInnerHTML={{ __html: data }}
    sx={{
      alignSelf: 'start',
      fontFamily: 'primary.italic',
      fontSize: ['4rem', '4.8rem', '4.8rem', '4rem', '4.8rem', '6.4rem'],
      gridColumn: [
        '1/13',
        '1/span 9',
        '1/span 13',
        '1/span 11',
        '1/span 9',
        '2/span 10'
      ],
      gridRow: [2, 2, 1, 1, 1, 1],
      lineHeight: 1.1,
      mt: ['4.7rem', '4rem', '1.8rem', '2.4rem', '2.7rem', '4.1rem'],
      position: 'relative',
      zIndex: 1,
      '& > span': {
        fontFamily: 'primary.mediumStyled'
      }
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    dangerouslySetInnerHTML={{ __html: data }}
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['2.4rem', '2.8rem', '2.8rem', '2.4rem', '3.2rem', '3.2rem'],
      gridColumn: [
        '1/13',
        '2/span 10',
        '4/span 18',
        '7/25',
        '5/span 16',
        '6/span 14'
      ],
      gridRow: [3, 3, 2, 2, 2, 2],
      lineHeight: 1.2,
      mt: ['8.7rem', '18.4rem', '6rem', '5rem', '13.9rem', '14.2rem'],
      textAlign: ['left', 'left', 'left', 'left', 'center', 'center']
    }}
  />
);
