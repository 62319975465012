import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Spacer } from 'components';
import {
  useCulinaryBoxesPageData,
  Header,
  Boxes,
  Selection
} from 'sections/culinaryBoxesPage';

// Cms data
import { useKisCulinaryBoxesPageData } from 'boxenStopHooks';

const CulinaryBoxes = () => {
  const { seo } = useCulinaryBoxesPageData();
  const { header, selection } = useKisCulinaryBoxesPageData();

  return (
    <>
      <SEO {...seo} />
      <Spacer
        height={['2.5rem', '4rem', '11rem', '7rem', '8.3rem', '6.2rem']}
      />
      <Header data={header} />
      <Spacer
        height={[
          '17rem',
          '26.9rem',
          '28.5rem',
          '16.5rem',
          '16.7rem',
          '13.5rem'
        ]}
      />
      <Boxes />
      <Spacer
        height={[
          '27rem',
          '38.2rem',
          '28.5rem',
          '30.4rem',
          '30.8rem',
          '24.5rem'
        ]}
      />
      <Selection data={selection} />
      <Spacer
        height={['13.1rem', '21rem', '12.6rem', '15.3rem', '23.8rem', '33rem']}
      />
    </>
  );
};

export default injectIntl(CulinaryBoxes);
