import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { IllustrationHeadline, GridSpacer } from 'components';

import {
  useShopPageData,
  ProductCategorySkeleton,
  PartnerWithProducts
} from 'sections/shopPage';

// Hooks
import {
  useProductCategories,
  useKisProductViewPageData
} from 'boxenStopHooks';

export const Boxes = () => {
  // Static Data
  const { product } = useShopPageData();
  const { product_card } = useKisProductViewPageData();
  const { partner_prefix, detail_text } = product_card;

  // Dynamic Data from API
  const { data: productCategoriesData, loading } = useProductCategories();

  // Only displays gift category
  function getBoxes() {
    if (productCategoriesData?.productCategories) {
      return productCategoriesData.productCategories.find(
        (category) => category.id === 'clb7o2qaz729772cobb7n2wcqb'
      );
    }
  }

  const boxesData = getBoxes();
  return loading ? (
    <ProductCategorySkeleton cardBackground={product.categories[0].bgColor} />
  ) : (
    <>
      {boxesData && (
        <Section key={boxesData.name}>
          <IllustrationHeadline
            categoryName={boxesData.name}
            image={boxesData.coverImage.url}
            bgColor={product.categories[1].bgColor}
          />
          <PartnerWithProducts
            data={{
              partner: {
                name: 'PUR Manufaktur'
              },
              items: boxesData.items.items
            }}
            itemType={boxesData.itemType}
            partnerPrefix={partner_prefix}
            detailText={detail_text}
          />
        </Section>
      )}
    </>
  );
};
